import React from 'react';
import {
  ChakraProvider,
  Box,
  Container,
  HStack,
  Image,
  Button,
  Heading,
  VStack,
  Text,
  CardBody,
  Card,
  Divider,
  Grid,
  GridItem,
  AspectRatio,
  Link,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

import logo from './assets/i/logo.png';
import bg from './assets/i/bg.png';
import ready from './assets/i/ready.png';
import features from './assets/data/features';
import theme from './theme';
import { FaTelegram, IoMdMail } from 'react-icons/all';
import Terms from './Terms';
import Privacy from './Privacy';

function App() {
  const { isTermsOpen, onTermsOpen, onTermsClose } = useDisclosure()

  return (
    <ChakraProvider theme={theme}>
      <Container
        maxW="container.xl"
        p={0}
      >
        <HStack
          justifyContent={'space-between'}
          p={{
            base: 4,
            xl: 0,
          }}
        >
          <Image
            maxW={'100px'}
            src={logo}
          />
          <Button
            onClick={() => window.location.href = 'https://bot.simpleherbs.us'}
          >
            Login
          </Button>
        </HStack>
        <VStack
          backgroundImage={`url(${bg})`}
          backgroundSize={'cover'}
          backgroundPosition={'center center'}
          backgroundRepeat={'no-repeat'}
          p={4}
          minH={'600px'}
          justifyContent={'end'}
        >
          <Box
            color={'gray.200'}
            bg={'blackAlpha.700'}
            p={4}
            rounded={'xl'}
          >
            <Heading
              textTransform={'uppercase'}
            >
              Create your own shop chatbot in Telegram and start selling your products today!
            </Heading>
          </Box>
        </VStack>
        <Box
          h={6}
        />
        <VStack
          spacing={6}
          p={{
            base: 4,
            xl: 0,
          }}
        >
          <Card>
            <CardBody>
              <VStack
                alignItems={'start'}
              >
                <Text>
                  It's fast, easy, and affordable. Our platform provides everything you need to get started, from customizable templates to secure payment processing.
                </Text>
                <Text>
                  No technical skills required. Just create your shop and start selling in minutes. With our user-friendly interface, you can easily manage your inventory, track sales, and communicate with your customers. Plus, we offer competitive pricing and a range of features to help you grow your business.
                </Text>
                <Text>
                  Join the thousands of businesses already using our platform and take your sales to the next level. Sign up now and start selling!
                </Text>
              </VStack>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <VStack
                spacing={4}
              >
                <Heading
                  alignSelf={'start'}
                >
                  Features and Benefits
                </Heading>
                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                  w={'100%'}
                  gap={2}
                >
                  {features.map((feature) => (
                    <GridItem
                      key={feature.title}
                    >
                      <Card
                        h={'full'}
                        variant={'elevated'}
                        boxShadow={'none'}
                      >
                        <CardBody
                          h={'full'}
                        >
                          <Stack
                            spacing={4}
                            h={'full'}
                            direction={{
                              base: 'column',
                              sm: 'row'
                            }}
                            alignItems={{
                              base: 'stretch',
                              sm: 'center'
                            }}
                          >
                            <AspectRatio
                              ratio={1}
                              minW={'100px'}
                            >
                              <Image
                                src={feature.icon}
                                rounded={'lg'}
                              />
                            </AspectRatio>
                            <VStack
                              alignSelf={'stretch'}
                              alignItems={'start'}
                              h={'100%'}
                            >
                              <Text
                                fontWeight={'bold'}
                                fontSize={'2xl'}
                                textTransform={'capitalize'}
                              >
                                {feature.title}
                              </Text>
                              <Text>{feature.description}</Text>
                            </VStack>
                          </Stack>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </Grid>
              </VStack>
            </CardBody>
          </Card>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
            gap={8}
          >
            <GridItem>
              <Image
                rounded={'lg'}
                src={ready}
              />
            </GridItem>
            <GridItem>
              <VStack>
                <Heading>Ready to take your small business to the next level?</Heading>
                <Divider />
                <Text>
                  Sign up today and start creating your own shop chat bot in Telegram! Our user-friendly platform makes it easy to set up your shop and get payments your way. Plus, book a demo with our team to see how it all works in action!
                </Text>
                <Box
                  h={4}
                />
                <HStack
                  w={'full'}
                >
                  <Button
                    size={'lg'}
                    onClick={() => window.open('mailto:info@simpleherbs.us')}
                  >
                    <HStack>
                      <IoMdMail />
                      <Text>Mail</Text>
                    </HStack>
                  </Button>
                  <Button
                    size={'lg'}
                    colorScheme={'blue'}
                    onClick={() => window.open('https://t.me/simple_herbs')}
                  >
                    <HStack>
                      <FaTelegram />
                      <Text>Telegram</Text>
                    </HStack>
                  </Button>
                </HStack>
              </VStack>
            </GridItem>
          </Grid>
          <HStack
            pb={4}
            spacing={8}
          >
            <Terms />
            <Privacy />
          </HStack>
        </VStack>
      </Container>
    </ChakraProvider>
  );
}

export default App;
