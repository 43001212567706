import {
  Button,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure, VStack,
} from '@chakra-ui/react';
import React from 'react';

const Privacy = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Text
        cursor={'pointer'}
        onClick={onOpen}
      >
        Privacy Policy
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms and Conditions</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            maxH={'60vh'}
            overflow={'scroll'}
          >
            <VStack
              alignItems={'start'}
            >
              <Text>We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website.</Text>
              <Text
                fontWeight={'bold'}
              >
                Information We Collect:
              </Text>
              <Text>We may collect personal information from you such as your name, email address, or phone number. We may also collect information about your usage of our website.</Text>
              <Text
                fontWeight={'bold'}
              >
                How We Use Your Information:
              </Text>
              <Text>We use your information to provide and improve our services. We may also use your information to communicate with you about our services, promotions, or other news.</Text>
              <Text
                fontWeight={'bold'}
              >
                Information We Share:
              </Text>
              <Text>We may share your information with third-party service providers who help us to provide our services. We may also share your information with law enforcement or other government agencies as required by law.</Text>
              <Text
                fontWeight={'bold'}
              >
                Security:
              </Text>
              <Text>We take reasonable measures to protect your information from unauthorized access, alteration, or destruction. However, we cannot guarantee that your information will be completely secure.</Text>
              <Text
                fontWeight={'bold'}
              >
                Changes to this Policy:
              </Text>
              <Text>We reserve the right to modify this Privacy Policy at any time. Any changes will be posted on this page, so please check back periodically.</Text>
            </VStack>
          </ModalBody>
          <ModalFooter
            justifyContent={'center'}
          >
            <Button
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Privacy