import easy from '../i/icons/easy.png'
import customizable from '../i/icons/customizable.png'
import fees from '../i/icons/fees.png'
import support from '../i/icons/support.png'
import secure from '../i/icons/secure.png'
import marketing from '../i/icons/marketing.png'

const features = [
  {
    title: 'Easy to use',
    description: 'Our platform is designed with simplicity in mind, so you can create your own shop chat bot in telegram in just a few clicks.',
    icon: easy,
  },
  {
    title: 'Customizable',
    description: 'You have complete control over the design and functionality of your shop chat bot, allowing you to create a personalized shopping experience for your customers.',
    icon: customizable,
  },
  {
    title: 'Secure payments',
    description: 'Our payment system is fully secure, so you can accept payments with confidence and protect your customers\' sensitive information.',
    icon: secure,
  },
  {
    title: 'Low fees',
    description: 'We charge a small percentage of your sales as service fees, so you can keep more of your profits and invest in growing your business.',
    icon: fees,
  },
  {
    title: 'Support 24/7',
    description: 'Our dedicated support team is available around the clock to help you with any questions or issues you may encounter.',
    icon: support,
  },
  {
    title: 'Marketing tools',
    description: 'Gain access to a variety of marketing tools and resources to help you promote your shop chat bot and reach more customers.',
    icon: marketing,
  }
]

export default features