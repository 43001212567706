import {
  Button, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text, useDisclosure, VStack,
} from '@chakra-ui/react';
import React from 'react';

const Terms = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Text
        cursor={'pointer'}
        onClick={onOpen}
      >
        Terms and Conditions
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms and Conditions</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            maxH={'60vh'}
            overflow={'scroll'}
          >
            <VStack
              alignItems={'start'}
            >
              <Text>Welcome to our web service! These terms and conditions outline the rules and regulations for the use of our service.</Text>
              <Text>By accessing this service, we assume you accept these terms and conditions in full. Do not continue to use this service if you do not accept all of the terms and conditions stated on this page.</Text>
              <Text
                fontWeight={'bold'}
              >
                1. License to Use
              </Text>
              <Text>Our service provides a platform for users to create their own shop chat bot in Telegram. Users are granted a non-exclusive, revocable license to access and use this platform for their own personal or commercial use.</Text>
              <Text
                fontWeight={'bold'}
              >
                2. Intellectual Property
              </Text>
              <Text>All content, trademarks, logos, and other intellectual property displayed on our website are the property of our service and/or our licensors. Users are not permitted to use, reproduce, distribute, or create derivative works based on this content without our explicit written consent.</Text>
              <Text
                fontWeight={'bold'}
              >
                3. Fees and Payments
              </Text>
              <Text>Users are responsible for paying any fees associated with using our service, including transaction fees and service fees. Our service reserves the right to change the fees at any time with prior notice to users.</Text>
              <Text
                fontWeight={'bold'}
              >
                4. Disclaimer of Liability
              </Text>
              <Text>Our service is provided on an "as is" and "as available" basis. Our service makes no representations or warranties of any kind, express or implied, as to the operation of our service or the information, content, or materials included on our service. Users expressly agree that their use of our service is at their sole risk.</Text>
              <Text
                fontWeight={'bold'}
              >
                5. Limitations of Liability
              </Text>
              <Text>Our service will not be liable to users or any third party for any direct, indirect, incidental, special, or consequential damages arising out of the use of our service, including, but not limited to, damages for loss of profits, use, data, or other intangible losses, even if our service has been advised of the possibility of such damages.</Text>
              <Text
                fontWeight={'bold'}
              >
                6. Indemnification
              </Text>
              <Text>Users agree to indemnify, defend, and hold harmless our service, its directors, officers, employees, agents, licensors, and suppliers from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of these terms and conditions or any activity related to our service (including negligent or wrongful conduct) by users or any other person accessing our service.</Text>
              <Text
                fontWeight={'bold'}
              >
                7. Severability
              </Text>
              <Text>If any provision of these terms and conditions is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these terms and conditions, such determination shall not affect the validity and enforceability of any other remaining provisions.</Text>
              <Text
                fontWeight={'bold'}
              >
                8. Governing Law
              </Text>
              <Text>These terms and conditions shall be governed by and construed in accordance with the laws of the State of California, United States, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</Text>
            </VStack>
          </ModalBody>
          <ModalFooter
            justifyContent={'center'}
          >
            <Button
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Terms;